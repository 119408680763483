import template from './conversation.html';
let Message;
class ConversationViewModel
{
	constructor(params)
	{
		Message = Grape.dataModel.get('message-manager.message');
		// To reply us inReplyTo with reference
		this.params = params;
		this.conversation_uuid = ko_helper.safe_observable(params.conversation_uuid);
		this.messages = ko.observableArray();
		this.messageCallback = ko.observable();
		this.messageCallback.subscribe(()=>{
			this.get_conversation();
		});
		this.channelMap = ko.observable();
		this.conversation_uuid.subscribe((val) => this.get_conversation(val) );

		//Paging
		this.current_page_number = ko.observable(1);
		this.page_size_options = ko.observableArray([3,6,12,20]);
		this.page_size_options.subscribe(() => {
			this.current_page_number(1);
		});
		this.current_page_size = ko.observable();
		this.current_page_size.subscribe(async () => {
			await this.get_conversation();
		});
		this.page_count = ko.computed(() => {
			return Math.ceil(this.messages().length/this.current_page_size());
		});

		this.init();
	}

	async init ()
	{
		await this.get_conversation();
	}

	async page_click(page_number)
	{
		this.current_page_number(page_number);
		await this.get_conversation();
	}

	async get_conversation ()
	{
		const channels = await Grape.cache.get('Channels');
		this.channelMap(channels.reduce((a,v)=>{a[v.name]=v;return a}, {}));
		if (this.conversation_uuid())
		{
			let res = await Grape.fetches.getJSON(`/api/messages/conversation/${this.conversation_uuid()}`,
				{
					fields: [
						'property', 'messages', 'messages.property', 'messages.address', 'category',
						'messages.address-full', 'messages.content', 'messages.content.property', 'messages.technical-info'
					]
				}
			);

			//TODO remove reply/forward objects
			let messages = res.messages.map((x, index) => ko.observable({
				message: x,
				isCollapsed: ko.observable(index !== 0),
				isEditable: ko.observable(false)
			}));

			this.messages(messages);
		}
	}

	collapse_message (data)
	{
		data.isCollapsed(true);
	}

	expand_message (data)
	{
		data.isCollapsed(false);
	}

	collapse_all_messages ()
	{
		this.messages().forEach(message => {
			message().isCollapsed(true);
		});
	}

	expand_all_messages ()
	{
		this.messages().forEach(message => {
			message().isCollapsed(false);
		});
	}

	async btn_delete_message (data, index)
	{
		data.isCollapsed(true);
		if (data.message.message_uuid){
			let msgInstance = new Message(data.message);
			await msgInstance.delete();
		}
		this.messages.splice(index(), 1);
	}

	btn_technical_info (data)
	{
		// TODO should this be generic? probably
		Grape.dialog.show(`message-technical-info-dialog`,data.message['technical-info']||{});
	}

	async btn_reply_message (data)
	{
		const DriverMessage = Grape.dataModel.get(`message-manager-${data.message.driver.toLowerCase()}.message`);
		const driverMessage = new DriverMessage();
		await driverMessage.set(data.message);
		const replyMessageObj = await driverMessage.createReply();
		this.messages.unshift(ko.observable({
			message: replyMessageObj,
			isCollapsed: ko.observable(false),
			isEditable: ko.observable(false)
		}));
		document.querySelector('.ps-mm-message-header')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}

	async btn_forward_message (data)
	{
		const DriverMessage = Grape.dataModel.get(`message-manager-${data.message.driver.toLowerCase()}.message`);
		const driverMessage = new DriverMessage();
		await driverMessage.set(data.message);
		const forwardMessageObj = await driverMessage.createForward();
		this.messages.unshift(ko.observable({
			message: forwardMessageObj,
			isCollapsed: ko.observable(false),
			isEditable: ko.observable(false)
		}));
		document.querySelector('.ps-mm-message-header')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}

	async btn_new_message (data)
	{
		let new_message = {};
		let messages = ko.toJS(data);
		for (let m of messages)
		{
			if (m.message.status === 'New')
			{
				return;
			}
			else if((m.message.status === 'Sent' || m.message.status === 'Received') && !!m.message.message_uuid)
			{
				new_message = {
					"address-full": [m.message["address-full"].find((a) => {
						return a.role == {'Sent': 'to', 'Received': 'from'}[m.message.status]
					})],
					'conversation_uuid': m.message.conversation_uuid,
					'driver': m.message.driver,
					'property': {},
					'status': 'New'
				};

				this.messages.unshift(ko.observable({
					message: new_message,
					isCollapsed: ko.observable(false),
					isEditable: ko.observable(false)
				}));

				document.querySelector('.ps-mm-message-header')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
				return;
			}
		}
	}
}

export default {
	name: 'ko-conversation',
	viewModel: ConversationViewModel,
	module_type: 'ko',
	template: template
}
