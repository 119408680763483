
import Message from './Message';

class Folder
{
	constructor(obj)
	{
		this.name = ko.observable();
		this.fullname = ko.observable();

		this.messages = ko.observableArray();
		this.total = ko.observable();
		this.filters = ko.observableArray();
		this.filters.subscribe(() => {
			this.loadMessages({limit:5, offset:5*(0), sortfield:'date_inserted', sortorder:'ASC'});
		});

		this.fields = ko.observableArray([
			{
				name: 'channel'
			},
			{
				name: 'status'
			},
			{
				name: 'date_inserted'
			},
			{
				name: 'diver'
			}
		]);

		this.set(obj);
	}

	set(obj)
	{
		if (obj.name)
			this.name(obj.name);
		if (obj.fullname)
			this.fullname(obj.fullname);
	}

	async loadMessages(data)
	{
		let options = this.buildOptions(data);
		const result = await Grape.fetches.getJSON('/api/record', options);
		if (result.status != 'OK')
			throw new Error(result.message);

		const messages = [];
		for (let f of result.records)
		{
			const message = new Message(f);
			messages.push(message);
		}
		this.total(result.total);
		this.messages(messages);
	}

	buildOptions(options)
	{
		const filters = [];
		const data = {};

		if (options.hasOwnProperty('limit'))
			data.limit = options.limit;
		else
			data.limit = 5;

		if (options.hasOwnProperty('offset'))
			data.offset = options.offset;
		else
			data.offset = 0;

		if (options.hasOwnProperty('sort_order'))
			data.sortorder = options.sort_order;
		else
			data.sortorder = 'ASC';

		if (options.hasOwnProperty('sort_field'))
			data.sortfield = options.sort_field;
		else
			data.sortfield = 'date_inserted';

		if (options.hasOwnProperty('msg_fields'))
			data.msg_fields = options.msg_fields;
		else
			data.msg_fields = ['address', 'property'];

		if (options.hasOwnProperty('msg_props'))
			data.msg_props = options.msg_props;
		else
			data.msg_props = ['subject', 'contentcount'];

		filters.push({field: 'folder_name', operator: '=', value: this.name()});
		for (let f of this.filters())
		{
			filters.push({field: f.field, operator: f.operator, value: f.value});
		}
		data.filter = filters;
		data.schema = 'messages';
		data.table = 'v_folder_conversations';

		return data;
	}
}

export default Folder;
