
import template from './message-address.html';

/**
 * Component for Message Address entry
 * Parameters:
 *  address
 *	role
 *	canEditAddress
 *	canEditRole
 *	onAdd
 *	onRemove
 */
class AddressEntryVM
{
	constructor(params)
	{
		this.params = params || {};
		this.address = ko_helper.safe_observable(params.name);
		this.addressRole = ko_helper.safe_observable(params.role, 'to');
		this.address_uuid = ko_helper.safe_observable(params.address_uuid);
		this.canEditAddress = params.canEditAddress || false;
		this.canEditRole = ko_helper.safe_observable(params.canEditRole || false);
		this.type = ko_helper.safe_observable(params.type);

		if (this.addressRole() == 'from')
			this.canEditRole(false);

		this.onAdd = params.onAdd;
		this.canAdd = (this.onAdd !== undefined) && this.onAdd instanceof Function;

		this.onRemove = params.onRemove;
		this.canRemove = (this.onRemove !== undefined) && (this.onRemove instanceof Function);
	}

	add_click()
	{
		if (this.canAdd)
			this.onAdd();
	}

	remove_click(index)
	{
		if (this.canRemove)
			this.onRemove(
				{
					address_uuid: this.address_uuid(),
					role: this.addressRole(),
					name: this.address()
				},
				index()
			);
	}

	updateAddressRole(addressRole)
	{
		this.addressRole(addressRole);
	}
}

export default {
	name: 'message-address',
	viewModel: AddressEntryVM,
	module_type: 'ko',
	template: template
};
