
import template from './message-technical-info-dialog.html';

/**
 * View Model
 */
class UIPSDialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {UIPSDialogClass} dialog - UIPSDialog class
	 */
	constructor(dialog)
	{
		this.dialog = dialog;
		this.content = ko.observable(dialog?.bindings?.content || 'No Content Provided');
		this.transmitInfo = ko.observable(dialog.bindings['transmit-info']);
		this.deliveryInfo = ko.observable(dialog.bindings['delivery-info']);
		this.errorInfo = ko.observable(dialog.bindings.error);
	}

	/**
	 * Handle click event for close button
	 */
	close_click()
	{
		this.dialog.close(false);
	}
}

/**
 * UIPSDialog
 */
class UIPSDialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element)
	{
		this.bindings = bindings; // bindings provided when calling dialog
		console.log(bindings);
		this.viewModel = new UIPSDialogViewModel(this); // Name of the ViewModel defined above
		this.name = 'UIPSDialog'; // Unique dialog name
		this.element = element; // HTML5 Dialog element *not main dialog container
	}

	init(){ // stuff to do when initially rendered
		console.log(this);
	};

	updateData(){ // place to put code that is fetching/updating data for easy reuse
		console.log(this);
	};
}

export default {
	name: 'message-technical-info-dialog',
	dialog_class: UIPSDialogClass,
	template: template,
	provider: 'ps'
};
