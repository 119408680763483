
class PropertyList
{
	constructor()
	{
		this.propertyList = ko.observableArray([]);
	}

	/**
	 * Set from object
	 */
	async set(obj)
	{
		const list = [];
		const names = [];
		for (let [k, v] of Object.entries(obj))
		{
			list.push({name: k, value: ko.observable(v)});
			names.push(k);
		}
		for (let item of this.propertyList() || [])
			if (names.indexOf(item.name) == -1)
				list.push(item);
		this.propertyList(list);
	}

	/**
	 * Return object of property names and values
	 */
	get property()
	{
		const obj = {};
		for (let item of this.propertyList() || [])
			obj[item.name] = item.value();
		return obj;
	}

	/**
	 * Get property value observable
	 */
	getPropertyObservable(name)
	{
		const prop = this.getProperty(name);
		return prop.value;
	}

	/**
	 * Get property value
	 */
	getPropertyValue(name)
	{
		const prop = this.getProperty(name);
		return prop.value();
	}


	/**
	 * Get property entry, will create one if it does not exist
	 */
	getProperty(name)
	{
		for (let item of this.propertyList() || [])
			if (item.name == name)
				return item;
		const item = {
			name: name,
			value: ko.observable()
		};
		this.propertyList.push(item);
		return item;
	}

	/**
	 * Set property value
	 */
	setPropertyValue(name, value)
	{
		const prop = this.getProperty(name);
		prop.value(value);
		return prop;
	}

}

export default PropertyList;
